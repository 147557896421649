span {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
}

header.masthead {
  position: relative;
  overflow: hidden;
  padding-top: calc(4rem + 72px);
  padding-bottom: 7rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

header.masthead::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 20%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.5) 80%,
    rgba(0, 0, 0, 0.6) 100%
  );
  z-index: 0;
}

header.masthead .masthead-content {
  z-index: 1;
  position: relative;
}

