.App {
  position: relative;
  overflow-x: clip;
}

p{
  font-size: 1rem ; 
}

p, h1, h2, h3, h4, h5, h6 {
  letter-spacing: 0.06rem;
}
