@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

:root {
  --primary: #1f3e72;
  --secondary: #33211d !important;
  --black: #131110;
  --blue: #4066ff;
  --lightBlue: #eeeeff;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
  scroll-behavior: smooth;
}

body{
  letter-spacing: 0.05em; 
}
.bg-grey {
  background: #1e2125;
}
.bg-azure {
  background: #f2f5ff;
}
.bg-blue {
  background: #2f2e41;
}
@media (max-width: 768px) {
  .col-lg-6 img {
    top: 0;
    left: 0;
  }
  .square {
    bottom: 10px;
    right: 10px;
  }
}