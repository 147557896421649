.h-wrapper {
  background-color: transparent;
  color: #000000;
}

nav a {
  font-family: "Lato";
  display: flex;
  font-size: 0.8rem !important;
}

nav a:hover {
  font-weight: 700;
  transition: all ease 0.2s;
}

.activenavbar {
  background-color: #ffffff;
  transition: all ease 0.2s;
  .nav-link {
    color: #000000 !important;
  }
}
.nav-link {
  margin-right: 1rem;
}

.navbar-toggler {
  border: none !important;
}
