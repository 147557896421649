button {
  padding: 0.5rem 0.75rem;
  background-color: var(--secondary);
  border: none;
  color: white;
  border-radius: 100px !important;
  transition: all 0.5s;
  text-decoration: none;
}

button a {
  display: inline-block;
  position: relative;
  transition: 0.5s;
  color: inherit;
}

button a,
button a:visited,
button a:hover,
button a:active {
  text-decoration: none;
  color: inherit;
}

button a:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}

button:hover a {
  padding-right: 15px;
}

button:hover a:after {
  opacity: 1;
  right: 0;
}

#navButton {
  padding: 0.5rem 0.75rem;
}

#headerButton {
  display: inline-block; /* or inline */
  font-size: 1.2rem;
  color: var(--secondary);
  padding: 0.5rem 1.5rem;
  background-color: #fff;
}

#solutionButton {
  padding: 0.5rem 1.7rem;
}

#fullStoryButton {
  display: inline-block;
  color: var(--secondary);
  background: white;
  border: 1px solid var(--secondary);
  border-width: 2px;
  padding: 0.5rem 1.5rem;
}

#requestButton {
  display: inline-block;
  color: white;
  background: transparent;
  border: 1px solid white;
  border-width: 2px;
  padding: 0.5rem 1.5rem;
}
#blackRequestButton {
  display: inline-block;
  color: white;
  background: #33211d;
  border: 1px solid #33211d;
  border-width: 2px;
  padding: 0.5rem 1.5rem;
}

#learnMoreButton {
  display: inline-block;
  color: #33211d;
  background: transparent;
  border: 1px solid #33211d;
  border-width: 2px;
  padding: 0.5rem 1.5rem;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px 20px;
  background-color: #33211d;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
